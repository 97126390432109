import React from 'react';

const Newsletter = () => {
    return(
        <div>
            <div style={{height: '200px', width: '400px', margin: '25% auto', border: '1px black solid'}}> <h2> Mapeo de mails </h2></div>
        </div>
    )
}

export default Newsletter