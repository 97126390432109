import React from 'react';

const Appointment = () => {
    return(
        <div>
            <div style={{height: '200px', width: '400px', margin: '25% auto', border: '1px black solid'}}> <h2> Mapeo de turnos </h2></div>
        </div>
    )
}

export default Appointment